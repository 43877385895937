import React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import SEO from "../components/seo";
import { SliceZones } from "../components/slices/slice-zones";
import Layout from "../components/full-page/layout";
import { ColorThemeContextProvider } from "../contexts/colorThemeContext";
import CCLogoGray from '../images/cccluegrey.svg';
import CCLogoWhite from "../images/Claritycoat AF new.svg";

const GenericPage = ({ data, pageContext: { lang } }) => {
  if (!data) {
    return null;
  }

  const document = data.prismicGenericPage;

  const meta = [
    {
      name: `description`,
      content: document.data.meta_description.text,
    },
    {
      property: `og:title`,
      content: document.data.meta_title.text,
    },
    {
      property: `og:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `twitter:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `og:image`,
      content: document.data.share_image.url,
    },
  ];

  return (
    <>
      <SEO title={document.data.title.text} meta={meta} canonical_link={document.data.canonical_link?.url}/>
      <ColorThemeContextProvider>
        <Layout
          disappearHeader={true}
          initColorTheme={document.data.body.length > 1 ? document.data.body[0].primary.theme : 'light'}
          logo={document.data.body.length > 1 && document.data.body[0].primary.theme && document.data.body[0].primary.theme === 'light' ? CCLogoGray : CCLogoWhite }
          topNav={data.prismicHome?.data.menu_links}
          footerNav={data.prismicHome?.data.footer_links}
          blogListing={data.allPrismicBlogPost?.nodes}
          currentLanguage={lang}
        >
          <SliceZones slices={document.data.body} />
        </Layout>
      </ColorThemeContextProvider>
    </>
  )
};

export const query = graphql`
query GenericPageQuery($id: String, $lang: String) {
  prismicGenericPage(id: {eq: $id}, lang: {eq: $lang}) {
    _previewable
    id
    lang
    type
    url
    data {
      title {
        text
      }
      meta_title {
        text
      }
      share_image {
        url
      }
      meta_description {
        text
      }
      canonical_link {
        url
      }
      body {
        ... on PrismicGenericPageDataBodyFullpageCta {
          id
          slice_type
          primary {
            theme
            cta_1_link {
              url
            }
            cta_1_title
            cta_2_link {
              url
            }
            cta_2_title
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            subheading {
              html
            }
          }
        }
        ... on PrismicGenericPageDataBodyFullpageFeatures {
          id
          slice_type
          primary {
            theme
            cta_title
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            subheading {
              html
            }
            cta_link {
              url
            }
          }
          items {
            subtitle {
              text
            }
            title1 {
              text
            }
            image {
              url
            }
          }
        }
        ... on PrismicGenericPageDataBodyImageGallery1 {
          id
          slice_type
          primary {
            theme
          }
          items {
            description {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            title1 {
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyImageGallery2 {
          id
          slice_type
          primary {
            theme
            title1 {
              text
            }
            description {
              text
            }
          }
          items {
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
          }
        }
        ... on PrismicGenericPageDataBodyFullpage2Columns {
          id
          items {
            cta_title
            cta_link {
              url
            }
          }
          primary {
            theme
            content {
              html
            }
            heading {
              text
            }
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
            image_position
            subheading {
              html
            }
          }
          slice_type
        }
        ... on PrismicGenericPageDataBodyFullpageImage {
          id
          primary {
            theme
            image {
              url
            }
            image_mobile {
              url
            }
            video_url {
              url
            }
            video_mobile_url {
              url
            }
          }
          slice_type
        }
        ... on PrismicGenericPageDataBody2Columns {
            id
            slice_type
            items {
              cta_link {
                url
              }
              cta_title
            }
            primary {
              content {
                html
              }
              heading {
                text
              }
              image {
                url
              }
              image_position
              subheading {
                html
              }
            }
          }
          ... on PrismicGenericPageDataBody2Columns1 {
            id
            slice_type
            items {
              cta_link {
                url
              }
              cta_title
            }
            primary {
              content {
                html
              }
              heading {
                text
              }
              subheading {
                html
              }
            }
          }
      }
    }
  }
  
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default withPrismicPreview(GenericPage);