import React, {useContext, useEffect, useState} from 'react';
import '../../styles/main.v2.scss'
import '../../styles/main.scss'
import Header from "./header";
import BurgerMenu from "./burger-menu";
import Footer from "./footer";
import { ColorThemeContextProvider } from "../../contexts/colorThemeContext";
import TopArrow from "./top-arrow";
import { defaultLanguage } from "../../../prismic-configuration";

const scrollToTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'});
}

const Layout = ({
                  children,
                  disappearHeader = true,
                  logo,
                  links,
                  initColorTheme = 'light',
                  disableFooter = false,
                  headerClass='',
                  currentLanguage = defaultLanguage,
                  topNav = [],
                  footerNav = [],
                  blogListing = [],
                }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  useEffect(() => {
    // open side nav to select language if no language is selected
    const saved = localStorage.getItem("lang");
    const initialValue = JSON.parse(saved);
    setIsOpenMenu(!initialValue);
  }, []);

  const [isHiddenHeader, setIsHiddenHeader] = useState(false);

  const [colorTheme, setColorTheme] = useState(initColorTheme);

  useEffect(() => {
    const onScrollListener = () => {
      if (disappearHeader) {
        if (window.scrollY >= 300) {
          setIsHiddenHeader(true);
        } else {
          setIsHiddenHeader(false)
        }
      }
    };
    window.addEventListener("scroll", onScrollListener);

    return () => {
      window.removeEventListener("scroll", onScrollListener);
    };
  }, [])

  return (
    <div className={`gotham-ssm generic-page ${colorTheme}`} style={{backgroundColor: "white"}}>
      <ColorThemeContextProvider colorTheme={colorTheme} setColorTheme={setColorTheme}>
        <Header
          openMenu={() => setIsOpenMenu(true)}
          logo={logo}
          headerClass={headerClass}
          links={[
            // {url: 'https://shop.claritycoat.com', label: "Shop"},
          ]}
          isHidden={isHiddenHeader}
        />
        <TopArrow
          isHidden={!isHiddenHeader}
          moveToTop={scrollToTop}
        />
        <BurgerMenu
          isOpen={isOpenMenu}
          onClose={() => setIsOpenMenu(false)}
          currentLanguage={currentLanguage}
          topNav={topNav}
          blogListing={blogListing}
        />
        <section>
          {children}
        </section>
        {!disableFooter &&
          <Footer footerNav={footerNav} />
        }
      </ColorThemeContextProvider>
    </div>
  );
};

export default Layout;